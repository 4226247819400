import React from 'react';
import ArticleSectionNonCollapsible from '../article-section-non-collapsible';
import Notable from '../notable';
import { buildNotableId } from '../../utils';

import styles from './key-terms.module.scss';

const KeyTerms = ({ terms }) => {
  if (Object.keys(terms).length === 0) {
    return null;
  }
  return (
    <ArticleSectionNonCollapsible title={`Key Terms`} modifier={'standard'}>
      <ul className={styles.list}>
        {Object.keys(terms).map((delta) => (
          <li
            key={terms[delta].relationships.keyTerm.id}
            className={styles.item}
          >
            <Notable id={buildNotableId(['key-terms'], delta)}>
              <div className={styles.term}>
                {terms[delta].relationships.keyTerm.name}
              </div>
              <div className={styles.termDef}>{terms[delta].body}</div>
            </Notable>
          </li>
        ))}
      </ul>
    </ArticleSectionNonCollapsible>
  );
};

export default KeyTerms;
