import React from 'react';
import { groupItemsByTerm, buildNotableId } from '../../utils';
import ArticleSectionNonCollapsible from '../article-section-non-collapsible';
import ArticleSectionCollapsible from '../article-section-collapsible';
import Notable from '../notable/notable';

import styles from './key-program-documents.module.scss';

const KeyProgramDocuments = ({ documents }) => {
  const documentsByType = groupItemsByTerm(documents, 'type', (document) => ({
    title: document.link.title,
    url: document.link.url,
    description: document.description
  }));

  const documentKeys = Object.keys(documentsByType);
  if (!documentKeys.length) {
    return null;
  }

  return (
    <ArticleSectionNonCollapsible
      title={`Key Program Documents`}
      modifier={`collapsible-nested`}
    >
      {documentKeys.map((typeLabel) => (
        <ArticleSectionCollapsible
          title={typeLabel}
          id={['key-program-documents', typeLabel]}
          countDisplay={documentsByType[typeLabel].length}
          headingElement="h3"
          key={`key-program-documents--${typeLabel}`}
          modifier={`collapsible-nested`}
        >
          <ul className={styles.list}>
            {documentsByType[typeLabel].map((document, delta) => (
              <li key={`${typeLabel}--${delta}`} className={styles.item}>
                <Notable
                  id={buildNotableId(
                    ['key-program-documents', typeLabel],
                    delta
                  )}
                >
                  <h4 className={styles.heading}>
                    <a href={document.url}>{document.title}</a>
                  </h4>
                  <p className={styles.description}>{document.description}</p>
                </Notable>
              </li>
            ))}
          </ul>
        </ArticleSectionCollapsible>
      ))}
    </ArticleSectionNonCollapsible>
  );
};

export default KeyProgramDocuments;
