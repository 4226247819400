import React from 'react';
import { graphql } from 'gatsby';
import { getArticleCategory } from '../utils';

import PageLayout from '../components/page-layout/page-layout';
import SEO from '../components/seo';

import ArticleLayout from '../components/article-layout';
import ArticleTitleCard from '../components/article-title-card';
import KeyDesignDecisions from '../components/key-design-decisions';
import ArticleSection from '../components/article-section';
import KeyTerms from '../components/key-terms';
import References from '../components/references';
import ArticleSubsection from '../components/article-subsection';
import KeyProgramDocuments from '../components/key-program-documents';
import ArticleSectionCollapsibleBasic from '../components/article-section-collapsible-basic';
import ArticleSectionCollapsible from '../components/article-section-collapsible';
import CustomContentSections from '../components/custom-content-sections';
import { TocTrackedH2 } from '../components/toc-tracked-heading';
import Notable from '../components/notable';
import ArticleTaxonomy from '../components/article-taxonomy';
import { getSubtitle } from '../utils';

const CaseStudies = ({ data }) => {
  const caseStudy = data.nodeCaseStudies;
  const title = caseStudy.title;

  return (
    <PageLayout>
      <SEO title={title} />
      <ArticleLayout
        articleId={caseStudy.articleId}
        articleFileLink={caseStudy.articleFileLink}
      >
        {/* Title section */}
        <ArticleTitleCard
          title={title}
          eyebrow={getArticleCategory(caseStudy)}
          // The third arg truncates the subtitle. This is only necessary while there is testing data.
          subtitle={getSubtitle(
            caseStudy.relationships?.keyTerms,
            caseStudy.relationships?.countriesAndRegions?.name,
            true
          )}
        />

        {/* Purpose */}
        {caseStudy?.purpose ? (
          <ArticleSection id={['purpose']} modifier={'intro'}>
            <TocTrackedH2 id={['purpose']}>Purpose</TocTrackedH2>
            <Notable id="purpose">
              <p>{caseStudy?.purpose}</p>
            </Notable>
          </ArticleSection>
        ) : (
          ''
        )}

        {/* Key Terms */}
        {caseStudy?.relationships?.keyTerms ? (
          <KeyTerms terms={caseStudy?.relationships?.keyTerms} />
        ) : (
          ''
        )}

        {/* Abstract */}
        {caseStudy?.abstract?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Abstract`}
            body={caseStudy?.abstract?.processed}
          />
        ) : (
          ''
        )}

        {/* Context */}
        {caseStudy?.context?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Context`}
            body={caseStudy?.context?.processed}
          />
        ) : (
          ''
        )}

        {/* At a Glance */}
        {caseStudy?.introduction?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`At a Glance`}
            body={caseStudy?.introduction?.processed}
          />
        ) : (
          ''
        )}

        {/* Summary Evaluation */}
        {caseStudy?.summaryEvaluation?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Summary Evaluation`}
            body={caseStudy?.summaryEvaluation?.processed}
          />
        ) : (
          ''
        )}

        {/* Overview */}
        {caseStudy.background ||
        caseStudy.programDescription ||
        caseStudy.outcomes ? (
          <ArticleSectionCollapsible title={`Overview`}>
            {caseStudy.background && (
              <ArticleSubsection
                title={`Background`}
                parent={`overview`}
                body={caseStudy.background.processed}
              />
            )}
            {caseStudy.programDescription && (
              <ArticleSubsection
                title={'Program Description'}
                parent={`overview`}
                body={caseStudy.programDescription.processed}
              />
            )}
            {caseStudy.outcomes && (
              <ArticleSubsection
                title={`Outcomes`}
                parent={`overview`}
                body={caseStudy.outcomes.processed}
              />
            )}
          </ArticleSectionCollapsible>
        ) : (
          ''
        )}

        {/* Custom sections */}
        {caseStudy.relationships?.customContent ? (
          <CustomContentSections
            sections={caseStudy.relationships?.customContent}
          />
        ) : (
          ''
        )}

        {/* Key Design Decisions */}
        {caseStudy?.relationships?.keyDesignDecisions ? (
          <KeyDesignDecisions
            kdds={caseStudy?.relationships?.keyDesignDecisions}
          />
        ) : (
          ''
        )}

        {/* Evaluation */}
        {caseStudy?.evaluation?.processed ? (
          <ArticleSectionCollapsibleBasic
            title={`Evaluation`}
            body={caseStudy?.evaluation?.processed}
          />
        ) : (
          ''
        )}

        {/* References */}
        {caseStudy?.references && caseStudy?.references.length > 0 ? (
          <References references={caseStudy?.references} />
        ) : (
          ''
        )}

        {/* Key Program Documents (Resources) */}
        <KeyProgramDocuments documents={caseStudy.relationships?.resources} />

        {/* If no appendices, hide display */}
        {/* Appendices */}
        {caseStudy?.appendices?.processed &&
        caseStudy?.appendices?.processed.length > 0 ? (
          <ArticleSectionCollapsibleBasic
            title={`Appendices`}
            body={caseStudy?.appendices?.processed}
          />
        ) : (
          ''
        )}

        {/* Taxonomy */}
        {caseStudy?.relationships?.interventionType ||
        caseStudy?.relationships?.institutions ||
        caseStudy?.relationships?.countriesAndRegions ||
        caseStudy?.relationships?.crises ||
        caseStudy?.relationships?.issues ? (
          <ArticleSection id={['article-taxonomy']}>
            <TocTrackedH2 id={['article-taxonomy']}>Taxonomy</TocTrackedH2>
            <ArticleTaxonomy
              intervention_type={caseStudy?.relationships?.interventionType}
              institutions={caseStudy?.relationships?.institutions}
              countriesAndRegions={
                caseStudy?.relationships?.countriesAndRegions
              }
              crises={caseStudy?.relationships?.crises}
              issues={caseStudy?.relationships?.issues}
            />
          </ArticleSection>
        ) : (
          ''
        )}
      </ArticleLayout>
    </PageLayout>
  );
};

export const query = graphql`
  query CaseStudiesNodeQuery($slug: String!) {
    nodeCaseStudies(path: { alias: { eq: $slug } }) {
      title
      articleId: drupal_id
      articleFileLink: field_link_to_pdf
      relationships {
        keyDesignDecisions: field_key_design_decisions {
          ...kddQuery
        }
        keyTerms: field_key_terms {
          ...keyTermsQuery
        }
        resources: field_resources {
          ...keyResourcesQuery
        }
        customContent: field_custom_content {
          ...customContentQuery
        }
        author: field_author {
          name: field_name
          bio: field_bio
        }
        countriesAndRegions: field_countries_and_regions {
          name
        }
        crises: field_crises {
          name
        }
        institutions: field_institutions {
          name
        }
        interventionType: field_intervention_type {
          name
        }
        issues: field_issues {
          name
        }
      }
      abstract: field_abstract {
        processed
      }
      appendices: field_appendices {
        processed
      }
      background: field_background {
        processed
      }
      context: field_context {
        processed
      }
      evaluation: field_evaluation {
        processed
      }
      introduction: field_introduction {
        processed
      }
      outcomes: field_outcomes {
        processed
      }
      programDescription: field_program_description {
        processed
      }
      purpose: field_purpose
      summaryEvaluation: field_summary_evaluation {
        processed
      }
      references: field_references {
        title
        url: uri
      }
    }
  }
`;

export default CaseStudies;
